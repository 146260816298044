<template>
  <div>
    <h2>YAPU Management Settings</h2>
    <v-subheader>* Ensure that all values entered, synchronize with values from YAPU</v-subheader>
    <v-divider class="mb-4"></v-divider>

    <v-tabs v-model="tabs" centered>
      <v-tab v-for="n in tabHeaders" :key="n">
        {{ n }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs">
      <!-- General Details  -->
      <v-tab-item>
        <v-layout>
          <v-flex md6>
            <v-card height="97%" class="ma-2">
              <v-card-title class="">
                <h3>Home Types</h3>
                <v-spacer></v-spacer>
                <v-btn size="x-small" color="primary" @click="openDialog('Add Home Type')">Add Home Type</v-btn>
              </v-card-title>
              <v-data-table class="mt-1" :items="data.homeType" :headers="homeTypeHeaders">
                <template slot="items" slot-scope="props">
                  <td title="click to edit">
                    <v-edit-dialog :return-value.sync="props.item.name" lazy large persistent
                      @save="validateForm('home')" @cancel="close">
                      {{ props.item.name | capitalize }}
                      <template v-slot:input>
                        <v-text-field slot="input" v-validate="'required|min:3|max:50'" data-vv-scope="home"
                          data-vv-name="props.item.name" data-vv-as="home type"
                          :error-messages="errors.collect('home.props.item.name')" v-model="props.item.name"
                          label="Edit" single-line counter></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-checkbox @change="save()" v-model="props.item.required" primary hide-details></v-checkbox>
                  </td>
                  <td class="justify-center layout px-0">
                    <v-icon small @click="deleteItem(props.item,data.homeType)">
                      delete
                    </v-icon>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>

          <v-flex md6>
            <v-card height="97%" class="ma-2">
              <v-card-title class="">
                <h3>Business Types</h3>
                <v-spacer></v-spacer>
                <v-btn size="x-small" color="primary" @click="openDialog('Add Business Type')">Add Business Type</v-btn>
              </v-card-title>
              <v-data-table class="mt-1" :items="data.businessType" :headers="businessTypeHeaders">
                <template slot="items" slot-scope="props">
                  <td title="click to edit">
                    <v-edit-dialog :return-value.sync="props.item.name" lazy large persistent
                      @save="validateForm('business')" @cancel="close">
                      {{ props.item.name | capitalize }}
                      <template v-slot:input>
                        <v-text-field slot="input" v-validate="'required|min:3|max:50'" data-vv-scope="business"
                          data-vv-name="props.item.name" data-vv-as="business name"
                          :error-messages="errors.collect('business.props.item.name')" v-model="props.item.name"
                          label="Edit" single-line counter></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-checkbox @change="save()" v-model="props.item.required" primary hide-details></v-checkbox>
                  </td>
                  <td class="justify-center layout px-0">
                    <v-icon small @click="deleteItem(props.item,data.businessType)">
                      delete
                    </v-icon>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>

      </v-tab-item>

      <!-- EXPOSURE TAB -->
      <v-tab-item>
        <v-toolbar flat color="white">
          <h3>Exposure Types</h3>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="openDialog('Add New Exposure Type')">Add New Exposure Type
          </v-btn>
        </v-toolbar>
        <v-expansion-panel inset>
          <v-expansion-panel-content v-for="(item, i) in data.exposure" :key="i">
            <template v-slot:actions>
              <v-icon color="primary darken-3">keyboard_arrow_down</v-icon>
            </template>
            <template v-slot:header>
              <v-container grid-list-md text-xs-center>
                <v-layout row wrap>
                  <v-flex xs4>
                    Name :- <b>{{ item.type }}</b>
                  </v-flex>
                  <v-flex xs4>
                    Key :- <b>{{ item.keyName }}</b>
                  </v-flex>
                  <v-flex xs4>
                    <v-icon title="edit" small @click="openEditDialog(item,'exposure',i)">
                      edit
                    </v-icon>
                    <v-icon title="delete" small @click="deleteItem(item,data.exposure)">
                      delete
                    </v-icon>
                  </v-flex>
                </v-layout>
              </v-container>
            </template>

            <v-flex md12>
              <v-card height="97%" class="ma-2">
                <v-card-title class="">
                  <h3>Types</h3>
                  <v-spacer></v-spacer>
                  <v-btn size="x-small" @click="openDialog('Add Exposure Sub-Type', i)" color="primary">Add Type</v-btn>
                </v-card-title>
                <v-data-table class="mt-1" :items="item.details" :headers="exposureTypeHeaders">
                  <template slot="items" slot-scope="props">
                    <td title="click to edit">
                      <v-edit-dialog :return-value.sync="props.item.name" lazy large persistent
                        @save="validateForm('exposure')" @cancel="close">
                        {{ props.item.name | capitalize }}
                        <template v-slot:input>
                          <v-text-field slot="input" v-validate="'required|min:3|max:50'" data-vv-scope="exposure"
                            data-vv-name="props.item.name" data-vv-as="type"
                            :error-messages="errors.collect('exposure.props.item.name')" v-model="props.item.name"
                            label="Edit" single-line counter></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </td>
                    <td>
                      <v-checkbox @change="save()" v-model="props.item.required" primary hide-details></v-checkbox>
                    </td>
                    <td class="justify-center layout px-0">
                      <v-icon small @click="deleteItem(props.item,item.details)">
                        delete
                      </v-icon>
                    </td>
                  </template>
                </v-data-table>
              </v-card>
            </v-flex>

          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-tab-item>

      <!-- Sensitivity Tab -->
      <v-tab-item>
        <v-toolbar flat color="white">
          <h3>Sensitivity Types</h3>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="openDialog('Add New Sensitivity Type')">Add New Sensitivity
            Type</v-btn>
        </v-toolbar>
        <v-expansion-panel inset>
          <v-expansion-panel-content v-for="(item, i) in data.sensitivity" :key="i">
            <template v-slot:actions>
              <v-icon color="primary darken-3">keyboard_arrow_down</v-icon>
            </template>

            <template v-slot:header>
              <v-container grid-list-md text-xs-center>
                <v-layout row wrap>
                  <v-flex xs4>
                    Name :- <b>{{ item.name }}</b>
                  </v-flex>
                  <v-flex xs4>
                    Key :- <b>{{ item.keyName }}</b>
                  </v-flex>
                  <v-flex xs4>
                    <v-icon title="edit" small @click="openEditDialog(item,'sensitivity',i)">
                      edit
                    </v-icon>
                    <v-icon title="delete" small @click="deleteItem(item,data.sensitivity)">
                      delete
                    </v-icon>
                  </v-flex>
                </v-layout>
              </v-container>
            </template>

            <v-layout>
              <v-flex md6>
                <v-card height="97%" class="ma-2">
                  <v-card-title class="">
                    <h3>Types</h3>
                    <v-spacer></v-spacer>
                    <v-btn size="x-small" @click="openDialog('Add Sensitivity Sub-Type', i)" color="primary">Add Type
                    </v-btn>
                  </v-card-title>
                  <v-data-table class="mt-1" :items="item.sensitivityType" :headers="sensitivityTypeHeaders">
                    <template slot="items" slot-scope="props">
                      <td title="click to edit">
                        <v-edit-dialog :return-value.sync="props.item.name" lazy large persistent
                          @save="validateForm('sensitivity')" @cancel="close">
                          {{ props.item.name | capitalize }}
                          <template v-slot:input>
                            <v-text-field slot="input" v-validate="'required|min:3|max:50'" data-vv-scope="sensitivity"
                              data-vv-name="props.item.name" data-vv-as="type"
                              :error-messages="errors.collect('sensitivity.props.item.name')" v-model="props.item.name"
                              label="Edit" single-line counter></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td>
                        <v-checkbox @change="save()" v-model="props.item.required" primary hide-details></v-checkbox>
                      </td>
                      <td class="justify-center layout px-0">
                        <v-icon small @click="deleteItem(props.item,item.sensitivityType)">
                          delete
                        </v-icon>
                      </td>
                    </template>
                  </v-data-table>
                </v-card>
              </v-flex>

              <v-flex md6>
                <v-card height="97%" class="ma-2">
                  <v-card-title class="">
                    <h3>Materials</h3>
                    <v-spacer></v-spacer>
                    <v-btn size="x-small" @click="openDialog('Add Sensitivity Material-Type', i)" color="primary">Add
                      Material</v-btn>
                  </v-card-title>
                  <v-data-table class="mt-1" :items="item.material" :headers="sensitivityTypeHeaders">
                    <template slot="items" slot-scope="props">
                      <td title="click to edit">
                        <v-edit-dialog :return-value.sync="props.item.name" lazy large persistent
                          @save="validateForm('material')" @cancel="close">
                          {{ props.item.name | capitalize }}
                          <template v-slot:input>
                            <v-text-field slot="input" v-validate="'required|min:3|max:50'" data-vv-scope="material"
                              data-vv-name="props.item.name" data-vv-as="material"
                              :error-messages="errors.collect('material.props.item.name')" v-model="props.item.name"
                              label="Edit" single-line counter></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td>
                        <v-checkbox @change="save()" v-model="props.item.required" primary hide-details></v-checkbox>
                      </td>
                      <td class="justify-center layout px-0">
                        <v-icon small @click="deleteItem(props.item,item.material)">
                          delete
                        </v-icon>
                      </td>
                    </template>
                  </v-data-table>
                </v-card>
              </v-flex>
            </v-layout>

          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-tab-item>

      <!-- Adaptive Capacity Tab -->
      <v-tab-item>
        <v-toolbar flat color="white">
          <h3>Adaptive Capacity</h3>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="openDialog('Add New Adaptive Capacity')">
            Add New Adaptive Capacity
          </v-btn>
        </v-toolbar>
        <v-expansion-panel inset>
          <v-expansion-panel-content v-for="(item, i) in data.adaptiveCapacity" :key="i">
            <template v-slot:actions>
              <v-icon color="primary darken-3">keyboard_arrow_down</v-icon>
            </template>

            <template v-slot:header>
              <v-container grid-list-md text-xs-center>
                <v-layout row wrap>
                  <v-flex xs4>
                    Name :- <b>{{ item.type }}</b>
                  </v-flex>
                  <v-flex xs4>
                    Key :- <b>{{ item.keyName }}</b>
                  </v-flex>
                  <v-flex xs4>
                    <v-icon title="edit" small @click="openEditDialog(item,'adaptive',i)">
                      edit
                    </v-icon>
                    <v-icon title="delete" small @click="deleteItem(item,data.adaptiveCapacity)">
                      delete
                    </v-icon>
                  </v-flex>
                </v-layout>
              </v-container>
            </template>

            <v-flex md12>
              <v-card height="97%" class="ma-2">
                <v-card-title class="">
                  <h3>Types</h3>
                  <v-spacer></v-spacer>
                  <v-btn size="x-small" @click="openDialog('Add Adaptive Capacity Question', i)" color="primary">Add
                    Questions</v-btn>
                </v-card-title>
                <v-data-table class="mt-1" :items="item.questions" :headers="questionHeaders">
                  <template slot="items" slot-scope="props">
                    <td title="click to edit">
                      <v-edit-dialog :return-value.sync="props.item.name" lazy large persistent
                        @save="validateForm('questions')" @cancel="close">
                        {{ props.item.name | capitalize }}
                        <template v-slot:input>
                          <v-text-field slot="input" v-validate="'required|min:3|max:200'" data-vv-scope="questions"
                            data-vv-name="props.item.name" data-vv-as="question"
                            :error-messages="errors.collect('questions.props.item.name')" v-model="props.item.name"
                            label="Edit" single-line counter></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </td>
                    <td title="click to edit">
                      <v-edit-dialog :return-value.sync="props.item.keyName" lazy large persistent
                        @save="validateForm('questions')" @cancel="close">
                        {{ props.item.keyName | capitalize }}
                        <template v-slot:input>
                          <v-text-field slot="input" v-validate="'required|min:3|max:50'" data-vv-scope="questions"
                            data-vv-name="props.item.keyName" data-vv-as="maximum score"
                            :error-messages="errors.collect('questions.props.item.keyName')"
                            v-model="props.item.keyName" label="Edit" single-line counter></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </td>
                    <td>
                      <v-checkbox @change="save()" v-model="props.item.required" primary hide-details></v-checkbox>
                    </td>
                    <td class="justify-center layout px-0">
                      <v-icon small @click="deleteItem(props.item,item.questions)">
                        delete
                      </v-icon>
                    </td>
                  </template>
                </v-data-table>
              </v-card>
            </v-flex>

          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-tab-item>

    </v-tabs-items>

    <!-- form dialog -->

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex v-if="formTitle == 'Add Home Type'" xs12>
                  <va-input placeholder="Enter Home Type" label="Home Type" v-model="homeTypeName" type="text"
                    class="caption font-weight-bold" v-validate="'required|min:3|max:50'" data-vv-scope="yapu"
                    :error-messages="errors.collect('yapu.homeTypeName')" data-vv-validate-on="blur"
                    data-vv-name="homeTypeName" data-vv-as="home type"
                    hint="Ensure that all values entered, synchronize with values from YAPU" show-clean></va-input>
                </v-flex>
                <v-flex v-if="formTitle == 'Add Business Type'" xs12>
                  <va-input placeholder="Enter Business Type" label="Business Type" v-model="businessTypeName"
                    type="text" class="caption font-weight-bold" v-validate="'required|min:3|max:50'"
                    data-vv-scope="yapu" :error-messages="errors.collect('yapu.businessTypeName')"
                    data-vv-validate-on="blur" data-vv-name="businessTypeName" data-vv-as="business type"
                    hint="Ensure that all values entered, synchronize with values from YAPU" show-clean></va-input>
                </v-flex>
                <v-flex v-if="formTitle == 'Add New Exposure Type'" xs12>
                  <va-input placeholder="Enter Exposure Type" label="Type" v-model="exposureTypeName"
                    type="text" class="caption font-weight-bold" v-validate="'required|min:3|max:50'"
                    data-vv-scope="yapu" :error-messages="errors.collect('yapu.exposureTypeName')"
                    data-vv-validate-on="blur" data-vv-name="exposureTypeName" data-vv-as="exposure type" show-clean>
                  </va-input>
                </v-flex>
                <v-flex v-if="formTitle == 'Add New Exposure Type'" xs12>
                  <va-input placeholder="Enter Exposure Key Name" label="Key Name" v-model="exposureKeyName"
                    type="text" class="caption font-weight-bold" v-validate="'required|min:3|max:50'"
                    data-vv-scope="yapu" :error-messages="errors.collect('yapu.exposureKeyName')"
                    data-vv-validate-on="blur" data-vv-name="exposureKeyName" data-vv-as="exposure key name"
                    hint="Ensure that all values entered, synchronize with values from YAPU" show-clean></va-input>
                </v-flex>
                <v-flex v-if="formTitle == 'Add Exposure Sub-Type'" xs12>
                  <va-input placeholder="Enter Type" label="Type" v-model="exposureSubTypeName" type="text"
                    class="caption font-weight-bold" v-validate="'required|min:3|max:50'" data-vv-scope="yapu"
                    :error-messages="errors.collect('yapu.exposureSubTypeName')" data-vv-validate-on="blur"
                    data-vv-name="exposureSubTypeName" data-vv-as="exposure sub-type"
                    hint="Ensure that all values entered, synchronize with values from YAPU" show-clean></va-input>
                </v-flex>
                <v-flex v-if="formTitle == 'Add New Sensitivity Type'" xs12>
                  <va-input placeholder="Enter Sensitivity Type" label="Type" v-model="sensitivityTypeName"
                    type="text" class="caption font-weight-bold" v-validate="'required|min:3|max:50'"
                    data-vv-scope="yapu" :error-messages="errors.collect('yapu.sensitivityTypeName')"
                    data-vv-validate-on="blur" data-vv-name="sensitivityTypeName" data-vv-as="sensitivity type"
                    show-clean></va-input>
                </v-flex>
                <v-flex v-if="formTitle == 'Add New Sensitivity Type'" xs12>
                  <va-input placeholder="Enter Sensitivity Key Name" label="Key Name"
                    v-model="sensitivityKeyName" type="text" class="caption font-weight-bold"
                    v-validate="'required|min:3|max:50'" data-vv-scope="yapu"
                    :error-messages="errors.collect('yapu.sensitivityKeyName')" data-vv-validate-on="blur"
                    data-vv-name="sensitivityKeyName" data-vv-as="sensitivity key name"
                    hint="Ensure that all values entered, synchronize with values from YAPU" show-clean></va-input>
                </v-flex>
                <v-flex v-if="formTitle == 'Add Sensitivity Material-Type'" xs12>
                  <va-input placeholder="Enter Material" label="Material" v-model="sensitivityMaterialName" type="text"
                    class="caption font-weight-bold" v-validate="'required|min:3|max:50'" data-vv-scope="yapu"
                    :error-messages="errors.collect('yapu.sensitivityMaterialName')" data-vv-validate-on="blur"
                    data-vv-name="sensitivityMaterialName" data-vv-as="material type"
                    hint="Ensure that all values entered, synchronize with values from YAPU" show-clean></va-input>
                </v-flex>
                <v-flex v-if="formTitle == 'Add Sensitivity Sub-Type'" xs12>
                  <va-input placeholder="Enter Type" label="Type" v-model="sensitivitySubTypeName" type="text"
                    class="caption font-weight-bold" v-validate="'required|min:3|max:50'" data-vv-scope="yapu"
                    :error-messages="errors.collect('yapu.sensitivitySubTypeName')" data-vv-validate-on="blur"
                    data-vv-name="sensitivitySubTypeName" data-vv-as="sensitivity type"
                    hint="Ensure that all values entered, synchronize with values from YAPU" show-clean></va-input>
                </v-flex>
                <v-flex v-if="formTitle == 'Add New Adaptive Capacity'" xs12>
                  <va-input placeholder="Enter Adaptive Capacity" label="Type"
                    v-model="adaptiveCapacityTypeName" type="text" class="caption font-weight-bold"
                    v-validate="'required|min:3|max:50'" data-vv-scope="yapu"
                    :error-messages="errors.collect('yapu.adaptiveCapacityTypeName')" data-vv-validate-on="blur"
                    data-vv-name="adaptiveCapacityTypeName" data-vv-as="name" show-clean></va-input>
                </v-flex>
                <v-flex v-if="formTitle == 'Add New Adaptive Capacity'" xs12>
                  <va-input placeholder="Enter Adaptive Capacity Key Name" label="Key Name"
                    v-model="adaptiveCapacityKeyName" type="text" class="caption font-weight-bold"
                    v-validate="'required|min:3|max:50'" data-vv-scope="yapu"
                    :error-messages="errors.collect('yapu.adaptiveCapacityKeyName')" data-vv-validate-on="blur"
                    data-vv-name="adaptiveCapacityKeyName" data-vv-as="name"
                    hint="Ensure that all values entered, synchronize with values from YAPU" show-clean></va-input>
                </v-flex>
                <v-flex v-if="formTitle == 'Add Adaptive Capacity Question'" xs12>
                  <va-input placeholder="Enter Question" label="Question" v-model="adaptiveCapacityQuestion" type="text"
                    class="caption font-weight-bold" v-validate="'required|min:3|max:50'" data-vv-scope="yapu"
                    :error-messages="errors.collect('yapu.adaptiveCapacityQuestion')" data-vv-validate-on="blur"
                    data-vv-name="adaptiveCapacityQuestion" data-vv-as="name" show-clean></va-input>
                </v-flex>
                <v-flex v-if="formTitle == 'Add Adaptive Capacity Question'" xs12>
                  <va-input placeholder="Enter Key Name" label="Type" v-model="adaptiveCapacityQuestionKey" type="text"
                    class="caption font-weight-bold" v-validate="'required|min:3|max:50'" data-vv-scope="yapu"
                    :error-messages="errors.collect('yapu.adaptiveCapacityQuestionKey')" data-vv-validate-on="blur"
                    data-vv-name="adaptiveCapacityQuestionKey" data-vv-as="name"
                    hint="Ensure that all values entered, synchronize with values from YAPU" show-clean></va-input>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="close('yapu')">Cancel</v-btn>
          <v-btn color="blue darken-1" flat @click="format('yapu')">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog -->

    <v-dialog v-model="editdialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{formTitle}}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="editForm" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <va-input placeholder="Enter Type" label="Type" v-model="editedType" type="text"
                    class="caption font-weight-bold" v-validate="'required|min:3|max:50'" data-vv-scope="editForm"
                    :error-messages="errors.collect('editForm.editedType')" data-vv-validate-on="blur"
                    data-vv-name="editedType" data-vv-as="type" show-clean></va-input>
                </v-flex>
                <v-flex xs12>
                  <va-input placeholder="Enter Key Name" label="Key Name" v-model="editedKeyName" type="text"
                    class="caption font-weight-bold" v-validate="'required|min:3|max:50'" data-vv-scope="editForm"
                    :error-messages="errors.collect('editForm.editedKeyName')" data-vv-validate-on="blur"
                    data-vv-name="editedKeyName" data-vv-as="key name"
                    hint="Ensure that all values entered, synchronize with values from YAPU" show-clean></va-input>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="close('editForm')">Cancel</v-btn>
          <v-btn color="blue darken-1" flat @click="editItem('editForm')">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import YapuService from "../../../services/yapu-service";
  export default {
    data: () => ({
      tabs: null,
      tabHeaders: [
        'General Details', 'Exposure', 'Sensitivity', 'Adaptive Capacity',
      ],

      homeTypeHeaders: [{
          text: '*Name',
          align: 'left',
          value: 'name',
          sortable: true
        },
        {
          text: "Required?",
          value: 'required',
          sortable: false,
          align: "left"
        },
        {
          text: "Delete",
          value: "action",
          sortable: false,
          align: "center"
        }
      ],

      businessTypeHeaders: [{
          text: '*Name',
          align: 'left',
          value: 'name',
          sortable: true
        },
        {
          text: "Required?",
          value: 'required',
          sortable: false,
          align: "left"
        },
        {
          text: "Delete",
          value: "action",
          sortable: false,
          align: "center"
        }
      ],

      exposureTypeHeaders: [{
          text: '*Name',
          value: 'name',
          align: 'left',
          sortable: true
        },
        {
          text: "Required?",
          value: 'required',
          sortable: false,
          align: "left"
        },
        {
          text: "Delete",
          value: "action",
          sortable: false,
          align: "center"
        }
      ],

      sensitivityTypeHeaders: [{
          text: '*Name',
          value: 'name',
          align: 'left',
          sortable: true
        },
        {
          text: "Required?",
          value: 'required',
          sortable: false,
          align: "left"
        },
        {
          text: "Delete",
          value: "action",
          sortable: false,
          align: "center"
        }
      ],

      questionHeaders: [{
          text: 'Name',
          value: 'name',
          align: 'left',
          sortable: true
        },
        {
          text: '*Key',
          value: 'keyName',
          align: 'left',
          sortable: false
        },
        {
          text: "Required?",
          value: 'required',
          sortable: false,
          align: "left"
        },
        {
          text: "Delete",
          value: "action",
          sortable: false,
          align: "center"
        }
      ],

      formTitle: '',
      homeType: [],
      homeTypeName: '',
      businessType: [],
      businessTypeName: '',
      exposure: [],
      exposureTypeName: '',
      exposureKeyName: '',
      exposureSubTypeName: '',
      sensitivity: [],
      sensitivityTypeName: '',
      sensitivityKeyName: '',
      sensitivitySubTypeName: '',
      sensitivityMaterialName: '',
      adaptiveCapacity: [],
      adaptiveCapacityTypeName: '',
      adaptiveCapacityKeyName: '',
      adaptiveCapacityQuestion: '',
      adaptiveCapacityQuestionKey: '',


      res: [],
      dialog: false,
      index: 0,

      editdialog: false,
      editedKeyName: '',
      editedType: '',
      editedCheck: '',
      editedIndex: '',

      data: {
        homeType: [],
        businessType: [],
        exposure: [],
        sensitivity: [],
        adaptiveCapacity: [],
      },

    }),

    computed: {},

    created() {
      this.initialize();
    },

    methods: {
      async initialize() {
        this.res = await YapuService.findAll();
        if (this.res.length > 0) {
          this.data = {
            homeType: this.res[0].homeType,
            businessType: this.res[0].businessType,
            sensitivity: this.res[0].sensitivity,
            adaptiveCapacity: this.res[0].adaptiveCapacity,
            exposure: this.res[0].exposure,
          }
        }
      },

      openDialog(title, i) {
        this.index = i;
        this.formTitle = title;
        this.dialog = true;
      },

      openEditDialog(item, val, i) {
        this.editdialog = true;
        this.editedCheck = val;
        this.editedIndex = i;
        this.editedKeyName = item.keyName;

        switch (this.editedCheck) {
          case 'exposure':
            this.formTitle = "Edit Exposure Type";
            this.editedType = item.type;
            break;

          case 'sensitivity':
            this.formTitle = "Edit Sensitivity Type";
            this.editedType = item.name;
            break;

          case 'adaptive':
            this.formTitle = "Edit Adaptive Capacity Type";
            this.editedType = item.type;
            break;
        }
      },

      async editItem(el) {
        this.editdialog = false;
        switch (this.editedCheck) {
          case 'exposure':
            this.data.exposure[this.editedIndex].type = this.editedType;
            this.data.exposure[this.editedIndex].keyName = this.editedKeyName;
            this.save(el);
            break;

          case 'sensitivity':
            this.data.sensitivity[this.editedIndex].name = this.editedType;
            this.data.sensitivity[this.editedIndex].keyName = this.editedKeyName;
            this.save(el);
            break;

          case 'adaptive':
            this.data.adaptiveCapacity[this.editedIndex].type = this.editedType;
            this.data.adaptiveCapacity[this.editedIndex].keyName = this.editedKeyName;
            this.save(el);
            break;

          default:
            break;
        }
      },

      async deleteItem(item, arr) {
        const index = arr.indexOf(item)
        this.$toast.question("Are you sure you want to delete this record?", "", {
          timeout: 20000,
          close: false,
          overlay: true,
          position: "center",
          buttons: [
            [
              "<button><b>YES</b></button>",
              async (instance, toast) => {
                  arr.splice(index, 1)
                  this.save();
                  instance.hide({
                    transitionOut: "fadeOut"
                  }, toast, "button");
                },
                true,
            ],
            [
              "<button>NO</button>",
              function (instance, toast) {
                instance.hide({
                  transitionOut: "fadeOut"
                }, toast, "button");
              },
            ],
          ],
        });
      },

      close(el) {

        this.$validator.reset({
          scope: el
        })

        this.dialog = false;
        this.editdialog = false;
        this.homeTypeName = '';
        this.businessTypeName = '';

        this.exposureTypeName = '';
        this.exposureKeyName = '';
        this.exposureSubTypeName = '';

        this.sensitivityTypeName = '';
        this.sensitivityKeyName = '';
        this.sensitivitySubTypeName = '';
        this.sensitivityMaterialName = '';

        this.adaptiveCapacityTypeName = '';
        this.adaptiveCapacityKeyName = '';
        this.adaptiveCapacityQuestion = '';
        this.adaptiveCapacityQuestionKey = '';

        this.editedCheck = '';
        this.editedIndex = '';
        this.editedType = '';
        this.editedKeyName = '';
      },

      async format(el) {

        let isValid = await this.$validator.validateAll(el)
        if (!isValid) {
          this.$toast.error('Please check to ensure that all fields are valid.', '', {
            position: 'topCenter'
          })
          return
        }

        if (this.formTitle == 'Add Home Type') {
          this.data.homeType.push({
            name: this.homeTypeName.trim()
          })
        }

        if (this.formTitle == 'Add Business Type') {
          this.data.businessType.push({
            name: this.businessTypeName.trim()
          })
        }

        if (this.formTitle == 'Add New Exposure Type') {
          this.data.exposure.push({
            type: this.exposureTypeName.trim(),
            keyName: this.exposureKeyName.trim()
          })
        }

        if (this.formTitle == 'Add Exposure Sub-Type') {
          this.data.exposure[this.index].details.push({
            name: this.exposureSubTypeName.trim()
          })
        }

        if (this.formTitle == 'Add New Sensitivity Type') {
          this.data.sensitivity.push({
            name: this.sensitivityTypeName.trim(),
            keyName: this.sensitivityKeyName.trim()
          })
        }

        if (this.formTitle == 'Add Sensitivity Sub-Type') {
          this.data.sensitivity[this.index].sensitivityType.push({
            name: this.sensitivitySubTypeName.trim()
          })
        }

        if (this.formTitle == 'Add Sensitivity Material-Type') {
          this.data.sensitivity[this.index].material.push({
            name: this.sensitivityMaterialName.trim()
          })
        }

        if (this.formTitle == 'Add New Adaptive Capacity') {
          this.data.adaptiveCapacity.push({
            type: this.adaptiveCapacityTypeName.trim(),
            keyName: this.adaptiveCapacityKeyName.trim()
          })
        }

        if (this.formTitle == 'Add Adaptive Capacity Question') {
          this.data.adaptiveCapacity[this.index].questions.push({
            name: this.adaptiveCapacityQuestion.trim(),
            keyName: this.adaptiveCapacityQuestionKey.trim()
          })
        }
        this.save(el);
      },

      async save(el) {

        if (this.res.length > 0) {
          await YapuService.update({
            adaptiveCapacity: this.data.adaptiveCapacity,
            businessType: this.data.businessType,
            exposure: this.data.exposure,
            homeType: this.data.homeType,
            sensitivity: this.data.sensitivity,
          });
          this.$toast.success("Record Updated Successfully", '', {
            position: 'topLeft'
          })
        } else {
          await YapuService.create({
            adaptiveCapacity: this.data.adaptiveCapacity,
            businessType: this.data.businessType,
            exposure: this.data.exposure,
            homeType: this.data.homeType,
            sensitivity: this.data.sensitivity,
          });
          this.$toast.success("Record Created Successfully", '', {
            position: 'topLeft'
          })
        }
        this.initialize();
        this.close(el);
      },

      async validateForm(el) {
        let isValid = await this.$validator.validateAll(el)
        if (!isValid) {
          this.$toast.error('Please check to ensure that all fields are valid.', '', {
            position: 'topCenter'
          })
          this.initialize();
          return
        }
        this.save(el)
      }
    },
  };
</script>