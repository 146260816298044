<template>
 <div class="pa-3">
  <customer-form :opened.sync="custForm" @gotoprofile="showCustomerProfile($event)" @updated="onUpdated($event)" @created="onCreated($event)" :customer="customer"></customer-form>
  <business-form :opened.sync="businessForm" @gotoprofile="showBusinessProfile($event)" @updated="onUpdated($event)" @created="onCreated($event)" :business="business"></business-form>
  <!-- Right Navigation -->
    <v-navigation-drawer  width="240" fixed v-model="filterModel" clipped app permanent right>
      <v-layout class="pa-2">
        <!-- <v-btn small icon><v-icon>keyboard_arrow_down</v-icon></v-btn> <h2> Search Filters</h2> -->
        <v-spacer></v-spacer>
        <!-- <v-btn style="margin-top: 0px" small flat @click="resetFilters" icon>
          <v-icon>clear</v-icon>
        </v-btn> -->
      </v-layout>
    <!-- Personal Search filters -->
    <v-layout row wrap class="pa-2" v-if="tabModel === 'tab-person'">
      <v-flex style="margin-top: -10px">
        <va-input v-model="filters.trn" placeholder="Tax number" :width="'100%'" clearable />
      </v-flex>
      <v-flex style="margin-top: -20px">
        <va-input v-model="filters.first_name" placeholder="First name" :width="'100%'" clearable />
      </v-flex>
      <v-flex  style="margin-top: -20px">
        <va-input v-model="filters.last_name" placeholder="Last name" :width="'100%'" clearable />
      </v-flex>
      <v-flex  style="margin-top: -20px">
        <va-input v-model="filters.date_of_birth"  return-masked-value mask="####-##-##" placeholder="Date of birth (yyyy-mm-dd)" :width="'100%'" clearable />
      </v-flex>
      <v-flex xs12>
        <v-btn block color="primary" dark @click="fetchCustomers()">
          SEARCH
        </v-btn>
      </v-flex>
    </v-layout>

    <!-- Business Search Filters -->
    <v-layout row wrap class="pa-2" v-if="tabModel === 'tab-business'">
      <v-flex style="margin-top: -10px">
        <va-input v-model="businessFilters.trn" placeholder="Tax number" :width="'100%'" clearable />
      </v-flex>
      <v-flex style="margin-top: -20px">
        <va-input v-model="businessFilters.business_name" placeholder="Business Name" :width="'100%'" clearable />
      </v-flex>
      <v-flex  style="margin-top: -20px">
        <va-select 
          v-model="businessFilters.business_type" 
          placeholder="All" 
          :items="['All', 'Sole Proprietor','Partnership','Company']"
          :width="'100%'" clearable />
      </v-flex>
      <v-flex  style="margin-top: -20px">
        <va-input 
          v-model="businessFilters.date_of_inception"  
          return-masked-value mask="####-##-##" 
          placeholder="Date of Inception (yyyy-mm-dd)" 
          :width="'100%'" 
          clearable />
      </v-flex>
      <v-flex xs12>
        <v-btn block color="primary" dark @click="fetchBusinesses()">
          SEARCH
        </v-btn>
      </v-flex>
    </v-layout>
   
  </v-navigation-drawer>

  <v-tabs v-model="tabModel" right class="mb-3 elevation-2" slider-color="blue">
    <v-tab :href="`#tab-person`">Persons</v-tab>
    <v-tab :href="`#tab-business`">Businesses</v-tab>
  </v-tabs>

  <v-tabs-items v-model="tabModel">
    <v-tab-item :value="`tab-person`" class="pa-4">
      <v-toolbar dense  color="white" class="elevation-1">
        <v-toolbar-title >Customer Listing</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn  flat color="primary" v-authorize="'Create Customer'" @click="onAddCustomerClicked" >Add Customer</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table 
        :headers="headers" 
        :items="customers"
        :total-items="totalCustomers"
        :pagination.sync="custPagination"
        :rows-per-page-items='[20, 50, 80,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
        :loading="isLoading" class="elevation-1">
        <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
        <template slot="items" slot-scope="props">
          <td><img :src="props.item.avatar" style="border-radius: 50%; width 32px; height: 32px; margin-top: 4px"/></td>
          <td>{{props.item.trn || 'N/A'}}</td>
          <td>{{props.item.first_name | capitalize}}</td>
          <td>{{props.item.last_name | capitalize }}</td>
          <td>{{props.item.member_category | capitalize}}</td>
          <td>{{props.item.date_of_birth}}</td>
          <td>{{props.item.gender | capitalize}}</td>
          <td>
            <v-icon  v-authorize="'Update Customer'" small class="mr-2" @click="onEditItemClicked(props.index, props.item)">
              edit
            </v-icon>
          </td>
        </template>
        <template slot="no-results">
            <v-btn style="margin: auto 0" icon-text flat color="blue" @click="resetCustomerFilters">
              No data found. Click here to refresh.
              <v-icon>refresh</v-icon>
            </v-btn>
        </template>
      </v-data-table>
    </v-tab-item>

    <v-tab-item :value="`tab-business`" class="pa-4">
      <v-toolbar dense  color="white" class="elevation-1">
        <v-toolbar-title >Business Listing</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn  flat color="primary" @click="onAddBusinessClicked" >Add Business</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table 
        :headers="businessHeaders" 
        :items="businesses"
        :pagination.sync="busPagination"
        :total-items="totalBusinesses"
        :rows-per-page-items='[20,50, 80,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
        :loading="isLoading" class="elevation-1">
        <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
        <template slot="items" slot-scope="props">
          <td><img :src="props.item.logo" style="border-radius: 50%; width 32px; height: 32px; margin-top: 4px"/></td>
          <td>{{props.item.trn || 'N/A'}}</td>
          <td>{{props.item.business_name | capitalize }}</td>
          <td>{{props.item.business_type | capitalize}}</td>
          <td>
            <v-icon small class="mr-2" @click="onEditBusinessClicked(props.index, props.item)">
              edit
            </v-icon>
          </td>
        </template>
        <template slot="no-results">
            <v-btn style="margin: auto 0" icon-text flat color="blue" @click="resetBusinessFilters">
              No data found. Click here to refresh.
              <v-icon>refresh</v-icon>
            </v-btn>
        </template>
      </v-data-table>
    </v-tab-item>
  </v-tabs-items>

  </div>
</template>

<style type="css" scoped>
h1, h2, h3 {
  color: #505a6b;}

td {
  height: 24px !important;
}

.active {
  background: #eee;
}
</style>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import VaInput from '@/components/form-components/VaInput.vue'
import VaSelect from '@/components/form-components/VaSelect.vue'
import customerService from '@/services/customer-service'
import PaginationMixin from '@/mixins/PaginationMixin'
import _ from 'lodash'

import CustomerForm from '@/views/apps/customer-management/CustomerForm'
import BusinessForm from '@/views/apps/customer-management/BusinessForm'

export default {
  components: {
    VaInput,
    VaSelect,
    CustomerForm,
    BusinessForm
  },

  data() {
    return {
      tabModel: 'tab-person',
      mini: false,
      defaultCustomer: {
        contact_info: {},
        living_address: {type:'Living'},
        mailing_address: {type:'Mailing',},
        employment_info: {},
        school_info: {},
        identifications:[],
        spouse: {},
        nearest_contact: {}
      },
      defaultBusiness: {
        trn: '',
        logo: '',
        business_name             : '',
        business_type             : '',
        main_business_activity    : '',
        source_of_fund           : '',
        expected_monthly_sales: 0.00,
        contact_info: {},
        living_address: {type:'Living'},
        mailing_address: {type:'Mailing',},
        owners:[]
      },
      businessForm: false,
      customer: {},
      business: {},
      notFoundImg: '',
      custForm: false,
      isLoading: false,
      headers: [
        { text: 'Photo', align: 'left', sortable: false},
        { text: 'Tax Number', align: 'left', sortable: false,},
        { text: 'First Name', align: 'left', sortable: false },
        { text: 'Last Name', align: 'left', sortable: false},
        { text: 'Member Category', align: 'left', sortable: true, value:"member_category"},
        { text: 'Date of Birth', align: 'left', sortable: true, value: 'date_of_birth'},
        { text: 'Gender', align: 'left', sortable: true, value: 'gender'},
        { text: 'Action', value: '', sortable: false},
      ],
      businessHeaders: [
        { text: 'Photo', align: 'left', sortable: false},
        { text: 'Tax Registration Number', align: 'left', sortable: false,},
        { text: 'Business Name', align: 'left', sortable: false },
        { text: 'Business Type', align: 'left', sortable: false},
        { text: 'Action', value: '', sortable: false},
      ],
      customers: [],
      businesses: [],
      totalCustomers: 0,
      totalBusinesses: 0,
      selectedIndex: -1,
      filterModel: true,
      filters : {
        first_name: '',
        last_name: '',
        trn: '',
        date_of_birth: ''
      },
      businessFilters: {
        trn: '',
        business_name: '',
        business_type:'',
        date_of_inception: '',
      },
      custPagination: {},
      busPagination: {},
    }
  },

  created () {
    this.initialize()
    
  },

  beforeDestroy() {
    this.$toast.destroy()
  },

  watch: {
    custPagination: async function () {
      await this.fetchCustomers() 
    },

    busPagination: async function () {
        await this.fetchBusinesses() 
    },
    // Watch filter fields for changes and refresh the table when a field is cleared
    "filters.first_name": async function (newVal, oldVal) {
        if(!!!newVal) await this.fetchCustomers()
    },
    "filters.last_name": async function (newVal, oldVal) {
        if(!!!newVal) await this.fetchCustomers()
    },
    "filters.trn": async function (newVal, oldVal) {
        if(!!!newVal) await this.fetchCustomers()
    },
    "filters.date_of_birth": async function (newVal, oldVal) {
        if(!!!newVal) await this.fetchCustomers()
    }       
  },

  computed: {
    ...mapState([
      'appState',
    ]), 
    
  },
  
  methods: {
    ...mapMutations([
      'SHOW_UNEXPECTED_ERROR_DIALOG',
      "TOGGLE_SIDEBAR_MINI",
    ]),

    initialize() {
      this.TOGGLE_SIDEBAR_MINI();
      this.customer = {...this.defaultCustomer}
      this.business = {...this.defaultBusiness}
    },

    onAddBusinessClicked() {
      this.business = {...this.defaultBusiness}
      this.businessForm = true
    },

    onAddCustomerClicked() {
      this.customer = {...this.defaultCustomer}
      this.custForm = true
    },

    onEditItemClicked(index, item) {
      item.employment_info = item.employment_info || {}
      item.mailing_address = item.mailing_address || {}
      item.contact_info = item.contact_info || {}
      item.school_info = item.school_info || {}
      item.nearest_contact = item.nearest_contact || {}
      item.spouse = item.spouse || {}
      for(let key in item) { 
        item[key] = item[key]  || '' 
      }
      this.customer = _.clone(item)
      this.selectedIndex = index
      this.custForm = true
    },

    onEditBusinessClicked(index, item) {
      item.mailing_address = item.mailing_address || {}
      item.contact_info = item.contact_info || {}
      for(let key in item) { 
        item[key] = item[key]  || '' 
      }
      this.business = _.cloneDeep(item)
      this.selectedIndex = index
      this.businessForm = true
    },

    showBusinessProfile(ev) {
      setTimeout(()=>{
        this.business = ev;
        this.businessForm = true;
      }, 1000)
    },

    showCustomerProfile(ev) {
      setTimeout(()=>{
        this.customer = ev;
        this.custForm = true;
      }, 1000)
    },

    async fetchCustomers() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        const { sortBy, descending, page, rowsPerPage } = this.custPagination
        
        if(!sortBy) return

        let sortString = `&sort=${sortBy}:${descending ? 'desc': 'asc'}`
        let skip = page == 1 ? 0 : (page - 1) * rowsPerPage
        let limit= rowsPerPage

        let search = ''
        for(let key in  this.filters) {
          if(this.filters[key] && this.filters[key].trim()){
            if (["first_name", "last_name", "trn", "middle_name"].includes(key)) {
              search += `search=lk:${this.filters[key]}:and&`
             continue
            }
            search += `${key}=eq:${this.filters[key]}:or&`
          }
        }
               
        this.isLoading = true
        let result = await customerService.findAll(search, sortString, {skip: skip, limit: limit})
        this.customers = result.data.map((el)=>{
          el.avatar = el.avatar || require('@/assets/img/avatar.png')
          return el
        })
        this.totalCustomers = result.total 
      } catch(e) {
        console.log('fetching vustomer data: ', e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      } finally {
        this.isLoading = false
      }
    },

    async fetchBusinesses() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        
        const { sortBy, descending, page, rowsPerPage } = this.busPagination
        
        let skip = page == 1 ? 0 : (page - 1) * rowsPerPage
        let limit= rowsPerPage

        let search = '' 
        for(let key in  this.businessFilters) {
          if(this.businessFilters[key] && this.businessFilters[key].trim()){
           if(this.businessFilters[key] && this.businessFilters[key] !== "All") {
            search += `${key}=eq:${this.businessFilters[key]}:or&`
           }
          }
        }

        this.isLoading = true
        let result = await customerService.findBusinesses(search, '', {skip: skip, limit: limit})
        this.businesses = result.data.map((el)=>{
          el.logo = el.logo || require('@/assets/img/logo-here.png')
          return el
        })
        this.totalBusinesses = result.total
      } catch(e) {
        console.log('fetching business data: ', e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      } finally {
        this.isLoading = false
      }
    },

    onUpdated(customer) {
      if(this.custForm) {
        customer.avatar = customer.avatar || require('@/assets/img/avatar.png')
        this.customers.splice(this.selectedIndex, 1, customer)
      } else {
        customer.logo = customer.logo || require('@/assets/img/logo-here.png')
        this.businesses.splice(this.selectedIndex, 1, customer)
      }
      this.selectedIndex = -1
    },

    onCreated(customer) {
      if(this.custForm) {
        const { sortBy, descending, page, rowsPerPage } = this.custPagination
        customer.avatar = customer.avatar || require('@/assets/img/avatar.png')
        let len = this.customers.length
        if(len != rowsPerPage) {
          this.customers.unshift(customer)
        } else {
          this.customers.unshift(customer)
          this.customers.pop()
        }
        this.customer = customer
        this.totalCustomers++
      } else {
        const { sortBy, descending, page, rowsPerPage } = this.busPagination
        customer.logo = customer.logo || require('@/assets/img/logo-here.png')
        let len = this.customers.length
        if(len != rowsPerPage) {
          this.businesses.unshift(customer)
        } else {
          this.businesses.unshift(customer)
          this.businesses.pop()
        }
        this.business = customer
        this.totalBusinesses++
      }
    },

    async resetCustomerFilters() {
      this.filters = {
        first_name: '',
        last_name: '',
        trn: '',
        date_of_birth: ''
      }
      await this.fetchCustomers()
    },

    async resetBusinessFilters() {
      this.businessFilters = {
        trn: '',
        business_name: '',
        business_type:'',
        date_of_inception: '',
      }
      await this.fetchBusinesses()
    }

  }
}
</script>

 
