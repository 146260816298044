<template>
  <div>

    <LoanEditor />

    <v-dialog v-model="loaderDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading Loan Information
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card>
      <v-toolbar color="blue darken-3" dark >
        <v-btn  round color="blue darken-2" class="elevation-0" :to="`/app/settings/company`"  >
          EXIT
        </v-btn>
        <v-toolbar-title>
          Loan Information
        </v-toolbar-title>
      </v-toolbar>
        <!-- <v-toolbar-title>
          <v-btn flat light large class="mx-3 mt-5 mb-3">Search</v-btn>
        </v-toolbar-title> -->
      <v-toolbar flat tabs extension-height="48px">
        <template class="mx-0 px-0" v-slot:extension>
          <v-tabs class="mb-2" color="primary" dark grow icons-and-text>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab @click="progress='disbursed', skip = 0, fetchLoans()">
              Active Loans <v-icon>fas fa-calendar-check</v-icon>
            </v-tab>
            <v-tab @click="progress='pending disbursement', skip = 0, fetchLoans()">
              Pending Disbursement Loans <v-icon>fas fa-clock</v-icon>
            </v-tab>
            <v-tab @click="progress='denied', skip = 0, fetchLoans()">
              Denied Loans <v-icon>fas fa-ban</v-icon>
            </v-tab>
            <v-tab @click="progress='closed', skip = 0, fetchLoans()">
              Closed Loans <v-icon>fas fa-times-circle</v-icon>
            </v-tab>
            <v-tab @click="progress='cancelled', skip = 0, fetchLoans()">
              Cancelled Loans <v-icon>fas fa-calendar-times</v-icon>
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-layout class="px-5 mt-4" row wrap>
         <v-flex xs12>
          <!-- Search Filter Field -->
          <v-text-field 
            v-model="filter" 
            class="mb-3" 
            label="Search Account Number #" 
            prepend-inner-icon="search" 
            @click:prepend-inner="skip = 0, fetchLoans()" 
            @click:clear="filter = '', skip = 0, fetchLoans()" 
            solo 
            light 
            clearable 
          />
        </v-flex>
      </v-layout>

      <v-layout class="subtitle-2 px-7" row wrap>
        <v-flex xs4>
          <b>Total Loans:</b> {{ totalLoans ? totalLoans : "N/A" }}
        </v-flex>
        <v-flex xs4>
          <b>Total Current Balance:</b> {{ asMoney(currentBalance) }}
        </v-flex>
        <v-flex xs4>
          <b>Total Originating Balance:</b> {{ asMoney(originatingBalance) }}
        </v-flex>
      </v-layout>

      <v-data-table class="mt-4" :headers="headers" :items="accountsWithLoans" :expand="expand" item-key="ID" hide-actions :pagination.sync="pagination">
        <template v-slot:items="props">
          <tr @click="props.expanded = !props.expanded" class="hover-pointer">
            <td class="text-xs-center"><b>{{ props.item.main_account_number }}</b></td>
            <td class="text-xs-center">{{ props.item.account_type }}</td>
            <td class="text-xs-center">{{ props.item.account_branch.branch_name }}</td>
            <td class="text-xs-center">{{ props.item.account_branch.branch_code }}</td>
            <td class="text-xs-center"><v-icon>fa-folder-open</v-icon></td>
          </tr>
        </template>
        <template v-slot:expand="props">
          <v-card flat>
            <v-list class="grey lighten-4" three-line>
              <template v-for="(item, index) in props.item.loans">
                <v-list-tile class="hover-pointer px-7" :key="item.loan.ID">
                  
                  <v-list-tile-content :key="index">
                    <v-list-tile-title class="font-weight-bold">
                      Loan Product - {{ item.loan_policy.loan_product_name }}  
                    </v-list-tile-title>
                    <v-list-tile-sub-title class="font-weight-medium">
                      ID: {{ item.loan.bank_loan_id ? item.loan.bank_loan_id : "N/A" }}<br>
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title class="font-weight-medium">
                      Original Amount: {{ asMoney(item.loan.original_amount) }}<br>
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title class="font-weight-medium">
                      Current Balance: {{ asMoney(item.loan.current_balance) }}<br>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>

                  <v-list-tile-action>
                    <v-btn @click="showLoanEditModal(item.loan.ID, item.loan.bank_loan_id)" v-if="item.loan.loan_progress == 'disbursed'" color="primary" icon>
                      <v-icon>fas fa-info-circle</v-icon>
                    </v-btn>
                  </v-list-tile-action>

                </v-list-tile>
              </template>
            </v-list>
          </v-card>
        </template>
      </v-data-table>
      <div class="text-xs-center pt-2" v-if="this.accountsWithLoans.length != 0">
        <v-pagination v-model="page" :value="page" :length="length" :total-visible="pagination.visible" @input="setPaginationData()"></v-pagination>
      </div>
    </v-card>

    <v-dialog v-model="editDialog" persistent max-width="1200px">
      <v-card>
        <v-toolbar class="elevation-0" dark color="primary">
          <v-toolbar-title>Edit Loan Information</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="editDialog = false; fetchLoans(); fetchLoanSettings(); frequency_name = 'Monthly';">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-layout class="primary darken-1 title pa-4 text-md-center white--text font-weight-regular" dark color="primary" row wrap>
          <v-flex xs3><b>Account Number:</b> {{ selectedLoan.account ? selectedLoan.account.main_account_number : "N/A" }} </v-flex>
          <v-flex xs3><b>Account Holder:</b> {{ selectedAccount.account_holders ? capitalize(selectedAccount.account_holders[0].first_name) + " " + capitalize(selectedAccount.account_holders[0].last_name) : "N/A" }} </v-flex>
          <v-flex xs3><b>Loan ID:</b> {{ selectedLoan.account ? selectedLoan.bank_loan_id : "N/A" }} </v-flex>
          <v-flex xs3><b>Loan Product:</b> {{ selectedLoan.account ? selectedLoan.loan_policy.loan_product_name : "N/A" }} </v-flex>
        </v-layout>

        <v-layout class="mt-4" py-2 mb-3 mx-3 row>
          <v-flex md3 mr-3>
              <va-input v-model="bank_loanID" v-validate="'required|max:50'" data-vv-scope="loan"
                  :error-messages="errors.collect('loan.bank_loan_id')" placeholder=" "
                  :label="`Loan ID`" data-vv-validate-on="blur" data-vv-name="bank_loan_id"
                  data-vv-as="bank_loan_id" show-clean />
          </v-flex>
          <v-flex md3 mr-3>
            <label class="blue-grey--text">Original Loan Amount</label>
            <money v-bind="money" disabled v-model="selectedLoan.original_amount"
              v-validate="'required|max:50'" data-vv-scope="loan"
              :error-messages="errors.collect('loan.original_loan_amount')" placeholder=" "
              data-vv-validate-on="blur" data-vv-name="original_loan_amount"
              data-vv-as="original loan amount" show-clean>
            </money>
          </v-flex>
          <v-flex md3 mr-3>
            <label class="blue-grey--text">Current Loan Balance</label>
            <money v-bind="money" v-model="selectedLoan.current_balance" v-validate="'required|max:50'"
              data-vv-scope="loan" :error-messages="errors.collect('loan.current_loan_balance')"
              placeholder=" " data-vv-validate-on="blur" data-vv-name="current_loan_balance"
              data-vv-as="current loan balance" show-clean>
            </money>
          </v-flex>
          <v-flex md3 mr-3>
            <label class="blue-grey--text">Payment ({{frequency_name}})</label>
            <money v-bind="money" v-model="selectedLoan.requested_monthly_payment" v-validate="'required|max:50'"
              data-vv-scope="loan" :error-messages="errors.collect('loan.monthly_payment')"
              placeholder=" " label="Monthly Payment" data-vv-validate-on="blur"
              data-vv-name="monthly_payment" data-vv-as="monthly payment" show-clean>
            </money>
          </v-flex>
        </v-layout>

        <v-layout py-2 mb-3 mx-3 row>
          <v-flex class="text-xs-center" md3>
            <va-input v-model="selectedLoan.requested_tenure" v-validate="'required|max:50'" data-vv-scope="loan"
                :error-messages="errors.collect('loan.tenure')" placeholder=" "
                :label="`Tenure (${frequency_name})`" data-vv-validate-on="blur" data-vv-name="tenure"
                data-vv-as="tenure" show-clean />
          </v-flex>
          <v-flex class="text-xs-center" md3 mr-3>
              <va-input disabled v-model="start_date" class="grey--text" v-validate="'max:50'"
                  data-vv-scope="loan" :error-messages="errors.collect('loan.start_date')"
                  placeholder=" " label="Start Date (YYYY-MM-DD)" data-vv-validate-on="blur"
                  data-vv-name="start_date" data-vv-as="start date" show-clean />
          </v-flex>
          <v-flex class="text-xs-center" md3 mr-3>
              <va-input v-model="maturity_date" class="grey--text" v-validate="'required|max:50'"
                  data-vv-scope="loan" :error-messages="errors.collect('loan.maturity_date')"
                  placeholder=" " label="Maturity Date (YYYY-MM-DD)" data-vv-validate-on="blur"
                  data-vv-name="maturity_date" data-vv-as="maturity date" show-clean />
          </v-flex>
          <v-flex md3 mr-3>
              <label class="blue-grey--text">Total Cash Collateral</label>
              <money disabled v-bind="money" v-model="cash_collateral" v-validate="'required|max:50'"
                  data-vv-scope="loan" :error-messages="errors.collect('loan.cash_collateral')"
                  placeholder=" " data-vv-validate-on="blur" data-vv-name="cash_collateral"
                  data-vv-as="cash collateral" show-clean></money>
          </v-flex>
      </v-layout>

      <v-layout py-2 mb-3 mx-3 row>
        <v-flex md3>
            <label class="blue-grey--text">Total Non-Cash Collateral</label>
            <money disabled v-bind="money" v-model="non_cash_collateral"
                v-validate="'required|max:50'" data-vv-scope="loan"
                :error-messages="errors.collect('loan.non_cash_collateral')" placeholder=" "
                data-vv-validate-on="blur" data-vv-name="non_cash_collateral"
                data-vv-as="non cash collateral" show-clean></money>
        </v-flex>
        <v-flex md3 mr-3>
            <va-input disabled v-model="selectedLoan.current_credit_score" v-validate="'max:50'"
                data-vv-scope="loan" :error-messages="errors.collect('loan.average_credit_score')"
                placeholder=" " label="Average Credit Score" data-vv-validate-on="blur"
                data-vv-name="average_credit_score" data-vv-as="average credit score" show-clean />
        </v-flex>
        <v-flex md3 mr-3>
            <va-input v-model="selectedLoan.requested_interest" v-validate="'required|max:50'" data-vv-scope="loan"
                :error-messages="errors.collect('loan.interest_rate')" placeholder=" "
                label="Interest Rate (%)" data-vv-validate-on="blur" data-vv-name="interest_rate"
                data-vv-as="interest rate" show-clean />
        </v-flex>
        <v-flex mr-3 md3>
            <!-- <label class="blue-grey--text">Past Due Days</label> -->
            <va-input v-model="selectedLoan.past_due_days" v-validate="'required|max:50'" data-vv-scope="loan"
                :error-messages="errors.collect('loan.past_due_days')" placeholder=" "
                label="Past Due Days" data-vv-validate-on="blur" data-vv-name="past_due_days"
                data-vv-as="past due days" show-clean />
            <!-- <money v-bind="money" v-model="selectedLoan.past_due_amount" v-validate="'required|max:50'"
                data-vv-scope="loan" :error-messages="errors.collect('loan.past_due_amount')"
                placeholder=" " data-vv-validate-on="blur" data-vv-name="past_due_amount"
                data-vv-as="past due amount" show-clean>
            </money> -->
        </v-flex>
      </v-layout>

      <v-layout py-2 mb-3 mx-3 row>
        <v-flex mt-1 md3>
            <va-select :items="payment_methods" v-model="selectedLoan.payment_method"
                placeholder="Select Payment Method" data-vv-scope="loan" label="Payment Method"
                v-validate="'required'" :data-vv-name="'payment_method'"
                :error-messages="errors.first('loan.payment_method')"></va-select>
        </v-flex>
        <v-flex md3 mr-3>
            <va-input disabled v-model="loans_officer" v-validate="'max:50'" data-vv-scope="loan"
                :error-messages="errors.collect('loan.loans_officer')" placeholder=" "
                label="Loans Officer" data-vv-validate-on="blur" data-vv-name="loans_officer"
                data-vv-as="loans officer" show-clean />
        </v-flex>
        <v-flex md3 mr-3>
            <va-select
            label="Payment Frequency"
            :items="loanSettings.frequencies"
            :item-text="'name'"
            :item-value="'name'"
            placeholder="Select Payment Frequency"
            data-vv-scope="loan"
            v-validate="'required'"
            v-model="frequency_name"
            data-vv-name="frequency"
            data-vv-as="payment frequency"
            :error-messages="errors.first('loan.frequency')"
            />
        </v-flex>
        <v-flex md3 mr-3>
            <label class="blue-grey--text">Interest Accrued</label>
            <money v-bind="money" v-model="selectedLoan.interest_accrued"
              v-validate="'required|max:50'" data-vv-scope="loan"
              :error-messages="errors.collect('loan.interest_accrued')" placeholder=" "
              data-vv-validate-on="blur" data-vv-name="interest_accrued"
              data-vv-as="interest accrued" show-clean>
            </money>
        </v-flex>
      </v-layout>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary mr-2 mb-3 elevation-0 px-5" dark @click="saveLoan()">Save</v-btn>
      </v-card-actions>

      </v-card>
    </v-dialog>



  </div>
</template>

<script>
import LoanEditService from '../services/loan-edit-service';
import { Money } from "v-money";
import LoanEditor from '../components/Global-Loan-Editor';

export default {

  components: {
    Money,
    LoanEditor
  },

  data(){
    return {

      expand: false,
      loaderDialog: false,
      editDialog: false,

      selectedLoan: {},
      selectedAccount: {},
      selectedDisbursed: {},
      selectedRiskFactors: {},

      bank_loanID: 0,
      accountsWithLoans: [],
      originatingBalance: 0,
      currentBalance: 0,
      totalLoans: 0,
      totalAccounts: 0,
      headers: [
        { text: "Account Number", align: "center", sortable: true, value: "main_account_number" },
        { text: "Account Type", align: "center", sortable: true, value: "account_type" },
        { text: "Branch Name", align: "center", sortable: true, value: "account_branch.branch_name" },
        { text: "Branch Code", align: "center", sortable: true, value: "account_branch.branch_code" },
        {
          text: "View Loans",
          align: "center",
          sortable: false,
          value: "view_loans"
        }
      ],
      
      pagination: {
        rowsPerPage: 20,
        visible: 10
      },

      filter: "",
      progress: "disbursed",
      skip: 0,
      limit: 10,
      page: 1,
      length: 0,

      // Loan Edit Form
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false
      },

      loanSettings: {},
      start_date: '',
      maturity_date: '',
      cash_collateral: 0,
      non_cash_collateral: 0,

      payment_methods: [],
      payment_method: '',
      frequency_name: 'Monthly',
      loans_officer: '',

    }
  },

  computed: {

  },

  async mounted(){
    await this.fetchLoans();
    await this.fetchLoanSettings();
  },

  methods: {

    async fetchLoans(){
      this.loaderDialog = true;
      try {
        let results = await LoanEditService.findAllAccounts(this.progress, this.skip, this.limit, this.filter);
        this.originatingBalance = 0;
        this.currentBalance = 0;
        this.totalLoans = 0;
        this.totalAccounts = 0;
        this.accountsWithLoans = results.data.account;
        if(this.accountsWithLoans){
          this.originatingBalance = results.data.originalSum;
          this.currentBalance = results.data.totalBalance;
          this.totalLoans = results.data.count;
          this.totalAccounts = results.data.totalAccounts;
          this.pagination.totalItems = this.totalAccounts;
          this.length = Math.ceil(this.totalAccounts / this.limit);
        } else {
          this.pagination.totalItems = 0;
          this.length = 0;
        }
        console.log("Fetched Data", results.data);
      } catch (error) {
        console.log(error)
      }
      this.loaderDialog = false;
    },

    async fetchLoanSettings(){
      this.loaderDialog = true;
      try {
        let result = await LoanEditService.findLoanSettings();
        if (result !== {}) {
          for (let key in result) {
            if (
            typeof result[key] === "string" &&
            result[key].indexOf("{'") > -1
            ) {
            result[key] = JSON.parse(result[key].replace(/'/g, '"'));
            }
          }
          this.loanSettings = result;
        }
      } catch (error) {
        console.log(error)
      }
      this.loaderDialog = false;
    },

    async showLoanEditModal(loanID, bank_Loan_ID){
      this.loaderDialog = true;
      try {
        let resultLoan = await LoanEditService.findLoan(loanID);
        this.selectedLoan = resultLoan.data;
        this.bank_loanID = bank_Loan_ID;
        console.log("Selected Loan", this.selectedLoan);
        let resultAccount = await LoanEditService.findAccount(this.selectedLoan.account.ID);
        this.selectedAccount = resultAccount.data;
        let resultDisbursed = await LoanEditService.findDisbursedLoan(loanID);
        this.selectedDisbursed = resultDisbursed.data;

        if(this.selectedLoan.frequency_name)
        this.frequency_name = this.selectedLoan.frequency_name;
        
        if(this.selectedDisbursed)
        this.start_date = this.selectedLoan.date_created;
        this.maturity_date = this.selectedLoan.maturity_date;

        // Removes duplicates from array
        var uniqueArr = []
        if (this.selectedLoan.collaterals) {
          uniqueArr = this.selectedLoan.collaterals.reduce((unique, o) => {
            if (!unique.some(obj => obj.loanID === o.loanID && obj.pledged_amount === o
                    .pledged_amount)) {
                unique.push(o);
            }
            return unique;
          }, []);
        }

        // Calculate the sum of cash and non cash collaterals
        if (uniqueArr) {
          uniqueArr.forEach(collateral => {
            if (collateral.collateral_type == "Cash Account") {
              this.cash_collateral = this.cash_collateral + collateral.pledged_amount
            } else {
              this.non_cash_collateral = this.non_cash_collateral + collateral.pledged_amount
            }
          })
        }

        let resultRisks = await LoanEditService.findAllRisks("risk_factor_name=eq:Payment Method");
        console.log("Risks", resultRisks)
        this.selectedRiskFactors = resultRisks.data;

        let methodsForPayment = this.selectedRiskFactors[10].sub_risk_factors.map(res => res.sub_risk_factor_name);
        console.log(methodsForPayment)
        this.payment_methods = methodsForPayment;
        this.payment_method = this.selectedLoan.payment_method;
        this.loans_officer = this.selectedLoan.processing_officer ? this.selectedLoan.processing_officer.first_name + ' ' + this.selectedLoan.processing_officer.last_name : '';

        console.log("Selected Loan", this.selectedLoan);
        console.log("Selected Account", this.selectedAccount);
        console.log("Selected Disbursed Loan", this.selectedDisbursed);
        console.log("Selected Risk Factors", this.selectedRiskFactors);

      } catch (error) {
        console.log("Error", error);
      }

      this.loaderDialog = false;
      this.editDialog = true;
    },

    async saveLoan(){
      let isValid = await this.$validator.validateAll("loan");
      if (!isValid) {
          this.$toast.error("Unable to complete action please ensure all fields are valid", "", {
              position: "topCenter"
          });
          return;
      }

      let obj = {
          bank_loan_id: this.bank_loanID,
          frequency_name: this.frequency_name || 0,
          original_amount: this.selectedLoan.original_amount || 0,
          current_balance: this.selectedLoan.current_balance || 0,
          requested_monthly_payment: this.selectedLoan.requested_monthly_payment || 0,
          requested_tenure: this.selectedLoan.requested_tenure || 0,
          date_created: this.start_date || "",
          maturity_date: this.maturity_date || "",
          //total_cash_collateral: this.cash_collateral,
          //total_non_cash_collateral: this.non_cash_collateral,
          current_credit_score: this.selectedLoan.current_credit_score || 0,
          requested_interest: this.selectedLoan.requested_interest || 0,
          // past_due_days: this.past_due_days,
          past_due_days: this.selectedLoan.past_due_days || 0,
          // past_due_amount: this.selectedLoan.past_due_amount,
          processing_officer: this.selectedLoan.processing_officer || "",
          payment_method: this.selectedLoan.payment_method || "",          
          interest_accrued: this.selectedLoan.interest_accrued || 0
      }
        console.log('this.selectedLoan: ', this.selectedLoan);
      
      try {
        let result = await LoanEditService.updateLoanInformation(this.selectedLoan.ID, obj);
          console.log(result);
          this.$toast.success("Update successful", "", {
              position: "topCenter"
          });
      } catch (error) {
        console.log("Error", error);
      }finally{
        this.fetchLoans();
      }
    },

    setPaginationData() {
      this.skip = this.page * this.limit - this.limit;
      this.fetchLoans();
    },

    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },

  }

}
</script>

<style lang="css" scoped>
.px-7{
  padding-left:6.5rem;
  padding-right:6.5rem;
}
.v-toolbar__extension{
  padding: 0px !important;
}
.hover-pointer {
  cursor: pointer !important;
}
h1, h2, h3 {
  color: #505a6b;
}
</style>